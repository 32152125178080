import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import {
  logOutFirebase,
  removeArticleFromFirebase,
} from "../redux/firebaseAction"
import { Line } from "../components/styles/styles"

const LoggedInUser = () => {
  const dispatch = useDispatch()

  const { Error, email, SavedArticles } = useSelector(state => ({
    ...state.mainReducer,
    ...state.mainReducer.user.user, //extract email address from nested user object
  })) //select different slices of state controlled by reducers

  const logout = event => {
    event.preventDefault()
    dispatch(logOutFirebase())
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ borderRight: "1px dotted grey" }}>
        <Grid style={{ marginTop: "10px", marginRight: "20px" }} container>
          <Grid item>
            <SideBar item lg={12} xs={12} sm={12}>
              <CustomLink to="/dashboard">
                <Button aria-label="wines">#wines</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button aria-label="wines"> #food</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button aria-label="wines">#social</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button aria-label="wines">#The Lists</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button aria-label="wines">#food</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button aria-label="wines">#food</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button aria-label="wines">#food</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button aria-label="wines">#green</Button>
                {Error}
              </CustomLink>

              <CustomLink to="/dashboard">
                <Button
                  aria-label="Log Out"
                  variant="outlined"
                  type="submit"
                  onClick={logout}
                >
                  {" "}
                  logout{" "}
                </Button>
                {Error}
              </CustomLink>
              {/* <CustomLink to="/dashboard">
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    onClick={() => setShowSection(!showSection)}
                                >
                                    <ArchiveIcon /> Saved Articles
                                </Button>
                                {Error}
                            </CustomLink>

                            <CustomLink to="/dashboard">
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    onClick={() => setShowSection(!showSection)}
                                >
                                    {" "}
                                    <PodcastIcon /> Saved Podcasts
                </Button>
                                {Error}
                            </CustomLink>

                            <CustomLink to="/dashboard">
                                <Button variant="outlined" onClick={getFirestoreData}>
                                    {" "}
                                    getDatafrom firebase{" "}
                                </Button>
                            </CustomLink>
                            <CustomLink to="/dashboard">
                                <Button variant="outlined" onClick={getApiData}>
                                    {" "}
                                    getDatafromApi
                </Button>
                            </CustomLink> */}
            </SideBar>
          </Grid>
        </Grid>
      </div>
      <Grid style={{ marginLeft: "40px" }} container>
        {SavedArticles &&
          SavedArticles.map((article, index) => (
            <Grid
              key={index}
              item
              lg={3}
              xs={12}
              sm={4}
              style={{ margin: "10px", marginTop: "10px" }}
            >
              <CardStyled image={article.image}>
                <div className="test" style={{ display: "flex" }}>
                  <CardContent>
                    <Title>{article.title}</Title>
                  </CardContent>
                </div>
                <CardActionsStyled>
                  <ButtonStyled
                    onClick={() =>
                      dispatch(removeArticleFromFirebase(email, article.slug))
                    }
                    aria-label="More"
                    size="small"
                    color="primary"
                  >
                    x
                  </ButtonStyled>{" "}
                  <Link to={article.slug}>
                    <ButtonStyled
                      aria-label="More"
                      size="small"
                      color="primary"
                    >
                      Read More
                    </ButtonStyled>
                  </Link>
                </CardActionsStyled>
              </CardStyled>
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default LoggedInUser

const Title = styled.p`
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #000;
  // color: #fff;
  background: #fff;
  padding: 0px 10px 0px 10px;
`
const CustomLink = styled(Link)`
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #323232;

  a {
    text-decoration: none;
  }

  button {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-weight: 700;
  }
`
const SideBar = styled(Grid)`
  display: flex;
  flex-direction: column;
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #323232;
  margin-top: 10px;
  a {
    text-decoration: none;
  }
`
const CardStyled = styled(Card)`
  box-shadow: none;
  border: 1px solid #d4d4d4ba;
  background: url(${props => props.image}) no-repeat center;
`
const ButtonStyled = styled(Button)`
  font-family: "GT-Walsheim-Regular", sans-serif;
  color: #fff;
  background: black;
  font-size: 12px;
`
const CardActionsStyled = styled(CardActions)`
  font-size: 11px;
  display: flex;
  justify-content: space-between;
`
