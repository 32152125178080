import React from "react"
import Layout from "../components/layout"
import { useSelector } from "react-redux"
import LoggedInUser from "./loggedIn"
import LoginForm from "./login"

const Dashboard = () => {
  const { email } = useSelector(state => ({ ...state.mainReducer.user.user }))

  return <Layout>{email ? <LoggedInUser /> : <LoginForm />}</Layout>
}
export default Dashboard
